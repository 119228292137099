import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VForm,
    {
      ref: "form",
      staticClass: "mt-5",
      on: {
        submit: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.checkForm()
        },
      },
    },
    [
      _c(VTextField, {
        attrs: { label: _vm.$t("name"), rules: [_vm.required] },
        model: {
          value: _vm.company.Name,
          callback: function ($$v) {
            _vm.$set(_vm.company, "Name", $$v)
          },
          expression: "company.Name",
        },
      }),
      _c(
        VRow,
        [
          _c(
            VCol,
            { attrs: { cols: "12", md: "6" } },
            [
              _c(VTextField, {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: ["##.###.###/####-##"],
                    expression: "['##.###.###/####-##']",
                  },
                ],
                attrs: {
                  placeholder: "00.000.000/0000-00",
                  label: _vm.$t("company_id"),
                  rules: [
                    function (v) {
                      return (!!v && v.length === 18) || _vm.required()
                    },
                  ],
                },
                model: {
                  value: _vm.company.CNPJ,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "CNPJ", $$v)
                  },
                  expression: "company.CNPJ",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "12", md: "6" } },
            [
              _c(VTextField, {
                attrs: { label: _vm.$t("phone") },
                model: {
                  value: _vm.company.Phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "Phone", $$v)
                  },
                  expression: "company.Phone",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(VTextField, {
        attrs: { label: _vm.$t("address") },
        model: {
          value: _vm.company.Address,
          callback: function ($$v) {
            _vm.$set(_vm.company, "Address", $$v)
          },
          expression: "company.Address",
        },
      }),
      _c(VTextField, {
        attrs: { label: _vm.$t("email"), rules: [_vm.email_valid] },
        model: {
          value: _vm.company.Email,
          callback: function ($$v) {
            _vm.$set(_vm.company, "Email", $$v)
          },
          expression: "company.Email",
        },
      }),
      _c(VTextField, {
        attrs: { label: _vm.$t("site") },
        model: {
          value: _vm.company.Website,
          callback: function ($$v) {
            _vm.$set(_vm.company, "Website", $$v)
          },
          expression: "company.Website",
        },
      }),
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("report_by_powerBI")))]),
              _c(
                VRadioGroup,
                {
                  attrs: { center: "", row: "" },
                  model: {
                    value: _vm.reportByPowerBI,
                    callback: function ($$v) {
                      _vm.reportByPowerBI = $$v
                    },
                    expression: "reportByPowerBI",
                  },
                },
                [
                  _c(VRadio, {
                    attrs: { label: _vm.$t("yes"), value: true },
                  }),
                  _c(VRadio, {
                    attrs: { label: _vm.$t("no"), value: false },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.company.Pbi && _vm.reportByPowerBI && _vm.company.PowerBiReportId
            ? _c(
                VAlert,
                {
                  staticStyle: {
                    "padding-top": "8px",
                    "padding-bottom": "8px",
                    "padding-left": "4px",
                  },
                  attrs: { dense: "", outlined: "", type: "error" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("pbi_invalid_id_text")) + " ")]
              )
            : _vm._e(),
          _vm.reportByPowerBI
            ? _c(VTextField, {
                staticClass: "mt-0 pt-2 mb-3",
                attrs: { label: _vm.$t("powerBI_key"), rules: [_vm.required] },
                model: {
                  value: _vm.keyPowerBI,
                  callback: function ($$v) {
                    _vm.keyPowerBI = $$v
                  },
                  expression: "keyPowerBI",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        VExpansionPanels,
        [
          _c(
            VExpansionPanel,
            { staticClass: "mt-1" },
            [
              _c(VExpansionPanelHeader, [
                _vm._v(" " + _vm._s(_vm.$t("company_info")) + " "),
              ]),
              _c(
                VExpansionPanelContent,
                [
                  _c(VTextField, {
                    directives: [
                      {
                        name: "money",
                        rawName: "v-money",
                        value: _vm.money,
                        expression: "money",
                      },
                    ],
                    attrs: { label: _vm.$t("revenue") },
                    model: {
                      value: _vm.company.Revenue,
                      callback: function ($$v) {
                        _vm.$set(_vm.company, "Revenue", $$v)
                      },
                      expression: "company.Revenue",
                    },
                  }),
                  _c(VTextField, {
                    attrs: {
                      label: _vm.$t("reserved_quotas"),
                      type: "number",
                      suffix: "%",
                    },
                    on: {
                      change: function ($event) {
                        _vm.company.ReservedQuotas = parseFloat(
                          _vm.company.ReservedQuotas
                        )
                      },
                    },
                    model: {
                      value: _vm.company.ReservedQuotas,
                      callback: function ($$v) {
                        _vm.$set(_vm.company, "ReservedQuotas", $$v)
                      },
                      expression: "company.ReservedQuotas",
                    },
                  }),
                  _c(VTextField, {
                    directives: [
                      {
                        name: "money",
                        rawName: "v-money",
                        value: _vm.money,
                        expression: "money",
                      },
                    ],
                    attrs: { label: _vm.$t("fundraising_value") },
                    model: {
                      value: _vm.company.FundraisingValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.company, "FundraisingValue", $$v)
                      },
                      expression: "company.FundraisingValue",
                    },
                  }),
                  !_vm.partnersLoading
                    ? _c(
                        "div",
                        [
                          _c(
                            "p",
                            {
                              staticClass: "mb-0",
                              staticStyle: { "font-size": "11px" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("min_allocation_value")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            VRow,
                            { attrs: { align: "center" } },
                            [
                              _c(
                                VCol,
                                { attrs: { cols: "6", md: "6", lg: "8" } },
                                [
                                  _c(VSelect, {
                                    attrs: {
                                      items: _vm.partners,
                                      "item-text": "name",
                                      "hide-details": "",
                                      dense: "",
                                      label: _vm.$tc("partner", 1),
                                      "return-object": "",
                                      outlined: "",
                                    },
                                    on: { change: _vm.handlePartnerMinValue },
                                    model: {
                                      value: _vm.partnerSelected,
                                      callback: function ($$v) {
                                        _vm.partnerSelected = $$v
                                      },
                                      expression: "partnerSelected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  staticClass: "mb-2",
                                  attrs: { cols: "6", md: "6", lg: "2" },
                                },
                                [
                                  _c(VTextField, {
                                    attrs: {
                                      label: _vm.$t(
                                        "min_value_required_currency",
                                        { currency: "(R$)" }
                                      ),
                                      type: "number",
                                      prefix: "R$",
                                      "hide-details": "",
                                    },
                                    on: {
                                      change: function (e) {
                                        return _vm.handleMinValue(
                                          e,
                                          _vm.CurrencyEnum.BRL
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.minAllocationValueBRL,
                                      callback: function ($$v) {
                                        _vm.minAllocationValueBRL = $$v
                                      },
                                      expression: "minAllocationValueBRL",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  staticClass: "mb-2",
                                  attrs: { cols: "6", md: "6", lg: "2" },
                                },
                                [
                                  _c(VTextField, {
                                    attrs: {
                                      label: _vm.$t(
                                        "min_value_required_currency",
                                        { currency: "(US$)" }
                                      ),
                                      type: "number",
                                      prefix: "US$",
                                      "hide-details": "",
                                    },
                                    on: {
                                      change: function (e) {
                                        return _vm.handleMinValue(
                                          e,
                                          _vm.CurrencyEnum.USD
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.minAllocationValueUS,
                                      callback: function ($$v) {
                                        _vm.minAllocationValueUS = $$v
                                      },
                                      expression: "minAllocationValueUS",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(VTextField, {
                    directives: [
                      {
                        name: "money",
                        rawName: "v-money",
                        value: _vm.money,
                        expression: "money",
                      },
                    ],
                    attrs: { label: _vm.$t("profitability") },
                    model: {
                      value: _vm.company.Profitability,
                      callback: function ($$v) {
                        _vm.$set(_vm.company, "Profitability", $$v)
                      },
                      expression: "company.Profitability",
                    },
                  }),
                  _c("p", [_vm._v(_vm._s(_vm.$t("esg")) + ":")]),
                  _c(
                    VRow,
                    { staticClass: "mx-12" },
                    [
                      _c("span", { staticClass: "mt-1" }, [_vm._v("0")]),
                      _c(VSlider, {
                        staticClass: "mx-2",
                        attrs: {
                          min: "0",
                          max: "5",
                          "track-color": "#383838",
                          "thumb-color": "primary",
                          "thumb-label": true,
                        },
                        on: {
                          change: function ($event) {
                            _vm.company.ESG = parseFloat(_vm.company.ESG)
                          },
                        },
                        model: {
                          value: _vm.company.ESG,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "ESG", $$v)
                          },
                          expression: "company.ESG",
                        },
                      }),
                      _c("span", { staticClass: "mt-1" }, [_vm._v("5")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VExpansionPanel,
            { staticClass: "mt-1" },
            [
              _c(VExpansionPanelHeader, [
                _vm._v(" " + _vm._s(_vm.$t("investments_docs")) + " "),
              ]),
              _c(
                VExpansionPanelContent,
                [
                  _c(VSelect, {
                    attrs: {
                      solo: "",
                      outlined: "",
                      items: _vm.StockOptions,
                      label: _vm.$t("stock_type"),
                      "item-text": "Name",
                      "item-value": "Id",
                      disabled:
                        !_vm.StockOptions || _vm.StockOptions.length == 0,
                    },
                    model: {
                      value: _vm.company.StockTypeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.company, "StockTypeId", $$v)
                      },
                      expression: "company.StockTypeId",
                    },
                  }),
                  _c(VTextField, {
                    attrs: { label: _vm.$tc("adhesion_term", 1) },
                    model: {
                      value:
                        _vm.company.AdhesionContractClickSignlKey[
                          _vm.$i18n.locale
                        ],
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.company.AdhesionContractClickSignlKey,
                          _vm.$i18n.locale,
                          $$v
                        )
                      },
                      expression:
                        "company.AdhesionContractClickSignlKey[$i18n.locale]",
                    },
                  }),
                  _c(VTextField, {
                    attrs: { label: _vm.$t("newsletter_subscription") },
                    model: {
                      value:
                        _vm.company.NewsletterSubscriptionClickSignlKey[
                          _vm.$i18n.locale
                        ],
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.company.NewsletterSubscriptionClickSignlKey,
                          _vm.$i18n.locale,
                          $$v
                        )
                      },
                      expression:
                        "company.NewsletterSubscriptionClickSignlKey[$i18n.locale]",
                    },
                  }),
                  _c(VTextField, {
                    attrs: { label: _vm.$t("commitment_term_anvil") },
                    model: {
                      value:
                        _vm.company.CommitmentTermAnvilUrl[_vm.$i18n.locale],
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.company.CommitmentTermAnvilUrl,
                          _vm.$i18n.locale,
                          $$v
                        )
                      },
                      expression:
                        "company.CommitmentTermAnvilUrl[$i18n.locale]",
                    },
                  }),
                  _c(VTextField, {
                    attrs: { label: _vm.$t("investment_proxy_pj_anvil") },
                    model: {
                      value:
                        _vm.company.InvestmentProxyPJAnvilUrl[_vm.$i18n.locale],
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.company.InvestmentProxyPJAnvilUrl,
                          _vm.$i18n.locale,
                          $$v
                        )
                      },
                      expression:
                        "company.InvestmentProxyPJAnvilUrl[$i18n.locale]",
                    },
                  }),
                  _c(VTextField, {
                    attrs: { label: _vm.$t("investment_proxy_pf_anvil") },
                    model: {
                      value:
                        _vm.company.InvestmentProxyPFAnvilUrl[_vm.$i18n.locale],
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.company.InvestmentProxyPFAnvilUrl,
                          _vm.$i18n.locale,
                          $$v
                        )
                      },
                      expression:
                        "company.InvestmentProxyPFAnvilUrl[$i18n.locale]",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("commitment_term_text")))]
                  ),
                  _c("quill-editor", {
                    ref: "myQuillEditor",
                    staticClass: "my-4",
                    model: {
                      value: _vm.company.CommitmentTermText[_vm.$i18n.locale],
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.company.CommitmentTermText,
                          _vm.$i18n.locale,
                          $$v
                        )
                      },
                      expression: "company.CommitmentTermText[$i18n.locale]",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("investment_proxy_pj_text")))]
                  ),
                  _c("quill-editor", {
                    ref: "myQuillEditor",
                    staticClass: "my-4",
                    model: {
                      value:
                        _vm.company.InvestmentProxyPJText[_vm.$i18n.locale],
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.company.InvestmentProxyPJText,
                          _vm.$i18n.locale,
                          $$v
                        )
                      },
                      expression: "company.InvestmentProxyPJText[$i18n.locale]",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("investment_proxy_pf_text")))]
                  ),
                  _c("quill-editor", {
                    ref: "myQuillEditor",
                    staticClass: "my-4",
                    model: {
                      value:
                        _vm.company.InvestmentProxyPFText[_vm.$i18n.locale],
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.company.InvestmentProxyPFText,
                          _vm.$i18n.locale,
                          $$v
                        )
                      },
                      expression: "company.InvestmentProxyPFText[$i18n.locale]",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VExpansionPanel,
            { staticClass: "mt-1" },
            [
              _c(VExpansionPanelHeader, [
                _vm._v(" " + _vm._s(_vm.$t("investment_vehicle")) + " "),
              ]),
              _c(
                VExpansionPanelContent,
                [
                  _c(VCombobox, {
                    attrs: {
                      label: _vm.$t("select_vehicles"),
                      items: _vm.vehiclesOptions,
                      "item-text": "name",
                      "item-value": "id",
                      multiple: "",
                      solo: "",
                      outlined: "",
                    },
                    model: {
                      value: _vm.vehiclesSelected,
                      callback: function ($$v) {
                        _vm.vehiclesSelected = $$v
                      },
                      expression: "vehiclesSelected",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VExpansionPanel,
            { staticClass: "mt-1" },
            [
              _c(VExpansionPanelHeader, [
                _vm._v(" " + _vm._s(_vm.$t("video_options")) + " "),
              ]),
              _c(
                VExpansionPanelContent,
                [
                  _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("is_details_playlist"))),
                      ]),
                      _c(
                        VRadioGroup,
                        {
                          attrs: { center: "", row: "" },
                          model: {
                            value: _vm.company.IsDetailsPlaylist,
                            callback: function ($$v) {
                              _vm.$set(_vm.company, "IsDetailsPlaylist", $$v)
                            },
                            expression: "company.IsDetailsPlaylist",
                          },
                        },
                        [
                          _c(VRadio, {
                            attrs: { label: _vm.$t("playlist"), value: true },
                          }),
                          _c(VRadio, {
                            attrs: { label: _vm.$tc("video", 1), value: false },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(VTextField, {
                    staticClass: "mt-0 pt-0 mb-3",
                    attrs: {
                      label: _vm.$t("details_video"),
                      hint: _vm.$tc("vimeo_hint", 2),
                    },
                    model: {
                      value: _vm.company.DetailsVideo[_vm.$i18n.locale],
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.company.DetailsVideo,
                          _vm.$i18n.locale,
                          $$v
                        )
                      },
                      expression: "company.DetailsVideo[$i18n.locale]",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VExpansionPanel,
            { staticClass: "mt-1" },
            [
              _c(VExpansionPanelHeader, [
                _vm._v(" " + _vm._s(_vm.$t("overall")) + " "),
              ]),
              _c(
                VExpansionPanelContent,
                [
                  _c(VSelect, {
                    attrs: {
                      solo: "",
                      outlined: "",
                      items: _vm.categories,
                      label: _vm.$tc("category", 1),
                      "item-value": "id",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function (ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.translateCategoryOption(item)) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "item",
                        fn: function (ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.translateCategoryOption(item)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.company.CategoryId,
                      callback: function ($$v) {
                        _vm.$set(_vm.company, "CategoryId", $$v)
                      },
                      expression: "company.CategoryId",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("is_showcased")))]),
                      _c(
                        VRadioGroup,
                        {
                          attrs: { center: "", row: "" },
                          model: {
                            value: _vm.company.Showcased,
                            callback: function ($$v) {
                              _vm.$set(_vm.company, "Showcased", $$v)
                            },
                            expression: "company.Showcased",
                          },
                        },
                        [
                          _c(VRadio, {
                            attrs: { label: _vm.$t("yes"), value: true },
                          }),
                          _c(VRadio, {
                            attrs: { label: _vm.$t("no"), value: false },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.company.Showcased
                    ? _c(
                        "div",
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("pipeline_category"))),
                          ]),
                          _c(
                            VRadioGroup,
                            {
                              attrs: { center: "", row: "" },
                              on: { change: _vm.pipeline_category_changed },
                              model: {
                                value: _vm.company.PipelineCategory,
                                callback: function ($$v) {
                                  _vm.$set(_vm.company, "PipelineCategory", $$v)
                                },
                                expression: "company.PipelineCategory",
                              },
                            },
                            [
                              _c(VRadio, {
                                attrs: {
                                  label: _vm.$t("ongoing_opts"),
                                  value: 0,
                                },
                              }),
                              _c(VRadio, {
                                attrs: {
                                  label: _vm.$t("portfolio_companies"),
                                  value: 1,
                                },
                              }),
                              _vm.roundsEnv === "FALSE"
                                ? _c(VRadio, {
                                    attrs: {
                                      label: _vm.$t("exclusive_opportunities"),
                                      value: 2,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.pipelineIndexOptions.length > 0
                            ? _c(VSelect, {
                                attrs: {
                                  items:
                                    _vm.pipelineIndexOptions[
                                      _vm.company.PipelineCategory
                                    ],
                                  label: _vm.$t("index"),
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.company.PipelineIndex,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.company, "PipelineIndex", $$v)
                                  },
                                  expression: "company.PipelineIndex",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VExpansionPanel,
            { staticClass: "mt-1" },
            [
              _c(VExpansionPanelHeader, [
                _vm._v(" " + _vm._s(_vm.$t("photo_options")) + " "),
              ]),
              _c(VExpansionPanelContent, [
                _c("span", [_vm._v(_vm._s(_vm.$t("banner")))]),
                _c(
                  "div",
                  { staticClass: "mt-1" },
                  [
                    _c(
                      VRow,
                      { attrs: { align: "center" } },
                      [
                        _vm.company.Base64Banner != null
                          ? _c(VImg, {
                              staticClass: "ml-3",
                              attrs: {
                                src: _vm.company.Base64Banner,
                                "max-height": "5rem",
                                "max-width": "5rem",
                              },
                            })
                          : _vm.company.Banner != null
                          ? _c(VImg, {
                              staticClass: "ml-3",
                              attrs: {
                                src: _vm.show_photo(_vm.company.Banner),
                                "max-height": "5rem",
                                "max-width": "5rem",
                              },
                            })
                          : _vm._e(),
                        _c("input", {
                          ref: "imageBannerInput",
                          staticClass: "ml-4",
                          attrs: {
                            id: "imageBannerInput",
                            name: "imageBannerInput",
                            type: "file",
                            accept: "image/png, image/jpeg, image/bmp",
                          },
                          on: { change: _vm.setImageBanner },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "mt-2 mb-2" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("logo")))]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        VRow,
                        { attrs: { align: "center" } },
                        [
                          _vm.company.Base64Logo != null
                            ? _c(VImg, {
                                staticClass: "ml-3",
                                attrs: {
                                  src: _vm.company.Base64Logo,
                                  "max-height": "5rem",
                                  "max-width": "5rem",
                                },
                              })
                            : _vm.company.Logo != null
                            ? _c(VImg, {
                                staticClass: "ml-3",
                                attrs: {
                                  src: _vm.show_photo(_vm.company.Logo),
                                  "max-height": "5rem",
                                  "max-width": "5rem",
                                },
                              })
                            : _vm._e(),
                          _c("input", {
                            ref: "imageLogoInput",
                            staticClass: "ml-4",
                            attrs: {
                              id: "imageLogoInput",
                              name: "imageLogoInput",
                              type: "file",
                              accept: "image/png, image/jpeg, image/bmp",
                            },
                            on: { change: _vm.setImageLogo },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VCardActions,
        { staticClass: "pl-0 dxa_modal_actions" },
        [
          _c(
            VBtn,
            {
              staticClass: "dxa_modal_btnSuccess",
              attrs: {
                color: "primary",
                "min-width": "100",
                loading: _vm.loading,
                type: "submit",
              },
            },
            [_vm._v(_vm._s(_vm.$t("save")))]
          ),
          _c(
            VBtn,
            {
              staticClass: "dxa_modal_btnError",
              attrs: { color: "secondary", "min-width": "100" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("go_back")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }